<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout wrap>
      <v-flex md12 sm12 lg12>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12">
            <div class="card-title float-left">Gerätemanagement</div>
            <v-btn
              color="success"
              class="float-right"
              fab
              @click="openDialog()"
            >
              <i class="material-icons">add</i>
            </v-btn>
          </v-col>
          <v-col cols="12" offset-md="6" offset-lg="6" sm="12" md="6" lg="6">
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Suche"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12">
            <h4>Alle Geräte</h4>
            <v-data-table
              :headers="headers"
              ref="table"
              :items="items"
              :items-per-page="25"
              :footer-props="{
                'items-per-page-options': [10, 25, 50, 100, -1],
              }"
              :search="search"
            >
              <template v-slot:item.action="{ item }">
                <div class="text-center inline-flex">
                  <v-tooltip left v-if="role === 'admin' || role === 'pl'">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        fab
                        small
                        @click="editEquipment(item)"
                        color="primary"
                      >
                        <i class="material-icons">settings</i>
                      </v-btn>
                    </template>
                    <span>Gerät bearbeiten</span>
                  </v-tooltip>
                  <v-tooltip left v-if="role === 'admin' || role === 'pl'">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        @click="openAssignDialog(item)"
                        fab
                        small
                        color="primary"
                      >
                        <i class="material-icons">perm_identity</i>
                      </v-btn>
                    </template>
                    <span>Ausleiher zuweisen</span>
                  </v-tooltip>
                  <v-tooltip left v-if="role === 'worker'">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        @click="openAssignToMeDialog(item)"
                        fab
                        small
                        color="primary"
                      >
                        <i class="material-icons">arrow_forward</i>
                      </v-btn>
                    </template>
                    <span>Gerät ausleihen</span>
                  </v-tooltip>
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        @click="getEquipmentHistory(item.id)"
                        small
                        fab
                        color="primary"
                      >
                        <i class="material-icons">alarm_on</i>
                      </v-btn>
                    </template>
                    <span>Verleih Historie anzeigen</span>
                  </v-tooltip>
                  <v-tooltip left v-if="role === 'admin' || role === 'pl'">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        @click="confirmDeleteItem(item.id, item.name)"
                        color="error"
                        small
                        fab
                      >
                        <i class="material-icons">delete</i>
                      </v-btn>
                    </template>
                    <span>Gerät löschen</span>
                  </v-tooltip>
                </div>
              </template>
              <template v-slot:item.rented_at="{ item }">
                <div>{{ $formatDateTime(item.rented_at) }}</div>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12">
            <h4>Von mir geliehene Geräte</h4>
            <v-data-table
              :headers="headers"
              :items="myitems"
              :items-per-page="25"
              :footer-props="{
                'items-per-page-options': [10, 25, 50, 100, -1],
              }"
              :search="search"
            >
              <template v-slot:item.action="{ item }">
                <div class="text-center inline-flex">
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        @click="returnEquipment(item)"
                        color="primary"
                        small
                        fab
                      >
                        <i class="material-icons">arrow_back</i>
                      </v-btn>
                    </template>
                    <span>Gerät zurück geben</span>
                  </v-tooltip>
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        @click="getEquipmentHistory(item.id)"
                        small
                        fab
                        color="primary"
                      >
                        <i class="material-icons">alarm_on</i>
                      </v-btn>
                    </template>
                    <span>Verleih Historie anzeigen</span>
                  </v-tooltip>
                </div>
              </template>
              <template v-slot:item.rented_at="{ item }">
                <div>{{ $formatDateTime(item.rented_at) }}</div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
    <v-layout row justify-center>
      <v-dialog v-model="dialog" persistent max-width="800px">
        <v-card>
          <v-card-title>
            <span v-if="edit" class="headline">Gerät bearbeiten</span>
            <span class="headline" v-else>Neues Gerät erstellen</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-form ref="form">
                <v-layout wrap>
                  <v-flex xs12 sm6 md6>
                    <v-text-field
                      v-model="newEquipment.brand"
                      label="Marke*"
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field
                      v-model="newEquipment.model"
                      label="Model"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field
                      v-model="newEquipment.description"
                      label="Beschreibung"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field
                      v-model="newEquipment.seriennummer"
                      label="Seriennummer"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-container>
            <small>* Diese Felder müssen Sie ausfüllen</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="success"
              v-if="edit"
              text
              @click="createNewEquipment()"
              >Aktualisieren</v-btn
            >
            <v-btn color="success" text v-else @click="createNewEquipment()"
              >Anlegen</v-btn
            >
            <v-btn
              color="error"
              text
              @click="
                dialog = false;
                edit = false;
              "
              >Abbrechen</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-row justify="center">
      <v-dialog v-model="confirm" persistent max-width="400">
        <v-card>
          <v-card-title class="headline">Gerät löschen</v-card-title>
          <v-card-text>
            Sind sie sicher, dass Sie das Gerät
            <b>{{ deleteName }}</b> unwiederruflich löschen wollen?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="deleteItem()">Ja</v-btn>
            <v-btn color="error" text @click="confirm = false">Nein</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="history" persistent max-width="800">
        <v-card>
          <v-card-title class="headline">Verleih Historie</v-card-title>
          <v-card-text>
            <v-data-table
              :headers="headershistory"
              hide-default-footer
              :items="histories"
              :items-per-page="-1"
            >
              <template v-slot:item.rented_at="{ item }">
                <div>{{ $formatDateTime(item.rented_at) }}</div>
              </template>
              <template v-slot:item.returned_at="{ item }">
                <div>{{ $formatDateTime(item.returned_at) }}</div>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="closehistory">Schliessen</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="assignToMeDialog" persistent max-width="800px">
        <v-card>
          <v-card-title>
            <span class="headline">Gerät ausleihen</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-form ref="form">
                <v-layout wrap>
                  <div v-if="selectedItem.rented_by">
                    <span style="color: red"
                      >Das Gerät liegt aktuell bei "{{
                        selectedItem.name
                      }}".</span
                    >
                    <br />Möchten Sie wirklich das Gerät
                    <b
                      >{{ selectedItem.brand }} {{ selectedItem.model }} ({{
                        selectedItem.seriennummer
                      }})</b
                    >
                    ausleihen?
                  </div>
                  <div v-else>
                    Möchten Sie wirklich das Gerät
                    <b
                      >{{ selectedItem.brand }} {{ selectedItem.model }} ({{
                        selectedItem.seriennummer
                      }})</b
                    >
                    ausleihen?
                  </div>
                </v-layout>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="assignToMe()">Ja</v-btn>
            <v-btn color="error" text @click="assignToMeDialog = false"
              >Nein</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="assigndialog" persistent max-width="500px">
        <v-card>
          <v-card-title>
            <h3 class="center">
              Gerät {{ selectedItem.brand }} {{ selectedItem.model }} ({{
                selectedItem.seriennummer
              }})
            </h3>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form2">
                <v-layout wrap>
                  <v-flex xs12 sm12 md12>
                    <v-select
                      clearable
                      v-model="selectedItem.rented_by"
                      :items="users"
                      item-text="name"
                      item-value="id"
                      label="Person"
                      @change="changeName"
                    ></v-select>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" text @click="assignUser()">Zuweisen</v-btn>
            <v-btn color="error" text @click="assigndialog = false"
              >Abbrechen</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      confirm: false,
      assigndialog: false,
      assignToMeDialog: false,
      history: false,
      edit: false,
      deleteName: "",
      deleteId: "",
      search: "",
      userid: "",
      items: [],
      myitems: [],
      users: [],
      role: "",
      histories: [],
      selectedItem: {},
      newEquipment: {},
      headershistory: [
        {
          text: "Geliehen von",
          value: "name",
        },
        {
          text: "Geliehen am",
          align: "center",
          value: "rented_at",
        },
        {
          text: "Zürück am",
          align: "center",
          value: "returned_at",
        },
      ],
      headers: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Marke",
          value: "brand",
        },
        {
          text: "Modell",
          value: "model",
        },
        {
          text: "Bezeichnung",
          value: "description",
        },
        {
          text: "Seriennummer",
          value: "seriennummer",
        },
        {
          text: "Geliehen von",
          value: "name",
        },
        {
          text: "Geliehen am",
          value: "rented_at",
        },
        {
          text: "Aktion",
          value: "action",
        },
      ],
      rules: {
        required: (value) => !!value || "Required.",
      },
    };
  },
  mounted() {
    this.getEquipments();
    this.role = this.$store.getters.user.role;
  },
  methods: {
    createNewEquipment() {
      if (!this.$refs.form.validate()) {
        return;
      }
      let formData = new FormData();
      let equipment = JSON.parse(JSON.stringify(this.newEquipment));
      formData.append("json", JSON.stringify(equipment));
      let mode;
      if (this.edit) {
        mode = "edit";
      } else {
        mode = "new";
      }
      formData.append("mode", mode);
      this.$http({
        method: "post",
        url: "editEquipment.php",
        data: formData
      })
        //this.$http.post(url, equipment)
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.authorization
          ) {
            alert(
              "Sie haben keine Berechtigung für diese Funktion. Bitte wenden Sie sich an einen Administrator"
            );
            return;
          }
          if (response.status === 200) {
            let msg = "";
            const color = "success";
            if (this.edit) {
              let index = this.items.findIndex((e) => e.id === equipment.id);
              if (index > -1) {
                const items = JSON.parse(JSON.stringify(this.items));
                items[index] = equipment;
                this.items = items;
                msg = "Das Gerät wurde erfolgreich aktualisiert";
              }
            } else {
              equipment.id = response.data.id;
              this.items.push(equipment);
              msg = "Neues Gerät wurde erfolgreich gespeichert.";
            }
            this.edit = false;
            this.dialog = false;
            this.$store.dispatch("snackbar", { msg, color });
          } else {
            const msg =
              "Neue Mitarbeiter konnte nicht gespeichert werden. Bitte versuchen Sie es erneut.";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch((error) => {
          const response = error.response.data;
          if (
            response.status === 500 &&
            response.message === "NoLoggedInUser"
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
            return;
          }
        });
    },
    getEquipments() {
      /*  this.$http({
        method: "get",
        url: "equipment/equipment", //"getCustomers.php"
      }) */
      this.$http({
        method: "post",
        url: "getEquipments.php",
      })
        .then((response) => {
          if (
            response.status === 500 &&
            response.message === "NoLoggedInUser"
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (response.status === 200 && response.data && response.data.data) {
            this.myitems = [];
            this.items = [];
            const data = response.data.data;
            const userid = this.$store.getters.user.id;
            data.forEach((item) => {
              if (item.rented_by === userid) {
                this.myitems.push(item);
              } else {
                this.items.push(item);
              }
            });
          }
          if (response.data.users) {
            this.users = response.data.users;
          }
        })
        .catch((error) => {
          const response = error.response.data;
          if (
            response.status === 500 &&
            response.message === "NoLoggedInUser"
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
            return;
          }
        });
    },
    openDialog() {
      this.newEquipment = {
        brand: "",
        model: "",
        description: "",
        seriennummer: "",
      };
      this.dialog = true;
    },
    openAssignDialog(item) {
      this.selectedItem = JSON.parse(JSON.stringify(item));
      this.assigndialog = true;
    },

    openAssignToMeDialog(item) {
      this.selectedItem = JSON.parse(JSON.stringify(item));
      this.assignToMeDialog = true;
    },
    changeName(val) {
      if (val && val !== "") {
        const user = this.users.find((u) => {
          return u.id === val;
        });
        if (user) {
          this.selectedItem.username = user.name;
        }
      } else {
        this.selectedItem.username = "";
      }
    },
    closehistory() {
      this.histories = [];
      this.history = false;
    },
    getEquipmentHistory(id) {
      /*   this.$http
        .get("equipmenthis/equipmenthistory/" + id) */
      let formData = new FormData();
      formData.append("id", id);
      this.$http({
        method: "post",
        url: "getEquipmentHistory.php",
        data: formData,
      })
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success === false &&
            response.data.authorization
          ) {
            alert(
              "Sie haben keine Berechtigung für diese Funktion. Bitte wenden Sie sich an einen Administrator"
            );
            return;
          }
          if (response.status === 200 && response.data && response.data.data) {
            this.histories = response.data.data;
            this.history = true;
          }
        })
        .catch((error) => {
          const response = error.response.data;
          if (
            response.status === 500 &&
            response.message === "NoLoggedInUser"
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
            return;
          }
        });
    },
    assignToMe() {
      const userid = this.$store.getters.user.id;
      this.selectedItem.rented_by = userid;
      this.assignUser();
    },
    returnEquipment(item) {
      //todo get last history entry and use it for return;
      item.rented_by = "";
      this.selectedItem = item;
      this.assignUser();
    },
    assignUser() {
      let item = this.selectedItem;
      let formData = new FormData();
      formData.append("equipmentid", item.id);
      const userid = item.rented_by;
      const historyid = item.historyid;
      if (userid && userid !== "") {
        formData.append("userid", userid);
      }
      if (historyid && historyid !== "") {
        formData.append("historyid", historyid);
      }
      this.$http({
        method: "post",
        url: "updateEquipmentHistory.php",
        data: formData,
        config: {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "application/json",
          },
        },
      })
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (response && response.data && response.data.success) {
            let msg = "";
            if (this.selectedItem && this.selectedItem.username) {
              msg =
                "Das Geräte wurde der Person " +
                this.selectedItem.username +
                " zugewiesen.";
            } else {
              msg = "Die Zuweisung der Person wurde entfernt.";
            }
           /*  let selectedItem = this.selectedItem;
            const item = this.items.find((i) => {
              return i.id == selectedItem.id;
            });
            if (selectedItem.rented_by && selectedItem.rented_by !== "") {
              const user = this.users.find((u) => {
                return u.id === selectedItem.rented_by;
              });
              if (item && user) {
                item.rented_by = user.id;
                item.name = user.name;
                item.rented_at = new Date();
              }
            } else{
              selectedItem.rented_by = undefined;
              selectedItem.name = undefined;
              selectedItem.rented_at = undefined;
            } */

            const color = "success";
            this.$store.dispatch("snackbar", { msg, color });
            this.selectedItem = {};
            this.assigndialog = false;
            this.assignToMeDialog = false;
            this.getEquipments();
          } else {
            const msg =
              "Das Geräte konnte nicht der Person " +
              this.selectedItem.username +
              " zugewiesen werden. Bitte versuchen Sie es erneut.";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch((error) => {
          const response = error.response.data;
          if (
            response.status === 500 &&
            response.message === "NoLoggedInUser"
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
            return;
          }
        });
    },
    editEquipment(item) {
      this.newEquipment = JSON.parse(JSON.stringify(item));
      this.edit = true;
      this.dialog = true;
    },
    confirmDeleteItem(id, name) {
      this.deleteName = name;
      this.deleteId = id;
      this.confirm = true;
    },
    deleteItem() {
      this.$http.delete("equipment/deleteequipment/" + this.deleteId);
      let formData = new FormData();
      formData.append("id", this.deleteId);
      this.$http({
        method: "post",
        url: "deleteEquipment.php",
        data: formData,
      })
        .then((response) => {
          if (
            response.status === 500 &&
            response.message === "NoLoggedInUser"
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (response.status === 203) {
            const msg =
              "Sie haben keine Berechtigung für diese Funktion. Bitte wenden Sie sich an einen Administrator";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
            this.confirm = false;
            return;
          }
          if (response.status === 200) {
            this.items.forEach((a, i) => {
              if (a.id === this.deleteId) {
                this.items.splice(i, 1);
                this.deleteName = "";
                this.deleteId = "";
                this.confirm = false;
              }
            });
            const msg = "Das Gerät wurde erfolgreich gelöscht.";
            const color = "success";
            this.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch((error) => {
          const response = error.response.data;
          if (
            response.status === 500 &&
            response.message === "NoLoggedInUser"
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
            return;
          }
          const msg =
            "Das Gerät konnte nicht gelöscht werden. Bitte versuchen Sie es erneut.";
          const color = "error";
          this.$store.dispatch("snackbar", { msg, color });
        });
    },
  },
};
</script>